@tailwind base;
@tailwind components;
@tailwind utilities;

/* ::-webkit-scrollbar {
  width: 0;
} */

html,
body {
  max-width: 100%;
  overflow-x: hidden;
}

.primary-color{
  color: rgb(48,175,188);
  border-color: rgb(48,175,188);
}


.primary-color {
  color: rgb(48,175,188);
}
.secondary-color {
  color: #000000;
}




@import url("https://fonts.googleapis.com/css2?family=Russo+One&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Sansita&display=swap");

.JustAnotherHand {
  font-family: "Just Another Hand", cursive;
}

.RussoOne {
  font-family: "Russo One";
}

.Sansita {
  font-family: "Sansita";
}

.K2D{
  font-family: 'K2D', sans-serif;
}

.Poppins{
  font-family: 'Poppins', sans-serif;
}

.Inter{
  font-family: 'Inter', sans-serif;
}
.Laila{
  font-family: 'Laila', serif;
}

#close-arrow {
  transform: rotate(180deg);
}

.shadowSubscribe{
  box-shadow: 20px 10px 20px #225c59;
}
.shadowSubscribe2{
  box-shadow: 20px 10px 20px  #86CB92;
}
.shadowSubscribe3{
  box-shadow: 20px 10px 20px  #FFC73B;
}

.inter {
  font-family: "Inter", sans-serif;
}

.loader  {
  animation: rotate 1s infinite;  
  height: 50px;
  width: 50px;
}

.loader:before,
.loader:after {   
  border-radius: 50%;
  content: '';
  display: block;
  height: 20px;  
  width: 20px;
}
.loader:before {
  animation: ball1 1s infinite;  
  background-color: #00a2ff;
  box-shadow: 30px 0 0 #ffffff;
  margin-bottom: 10px;
}
.loader:after {
  animation: ball2 1s infinite; 
  background-color: #00ffee;
  box-shadow: 30px 0 0 #000000;
}

@keyframes rotate {
  0% { 
    -webkit-transform: rotate(0deg) scale(0.8); 
    -moz-transform: rotate(0deg) scale(0.8);
  }
  50% { 
    -webkit-transform: rotate(360deg) scale(1.2); 
    -moz-transform: rotate(360deg) scale(1.2);
  }
  100% { 
    -webkit-transform: rotate(720deg) scale(0.8); 
    -moz-transform: rotate(720deg) scale(0.8);
  }
}

@keyframes ball1 {
  0% {
    box-shadow: 30px 0 0 #09ff00;
  }
  50% {
    box-shadow: 0 0 0 #09ff00;
    margin-bottom: 0;
    -webkit-transform: translate(15px,15px);
    -moz-transform: translate(15px, 15px);
  }
  100% {
    box-shadow: 30px 0 0 #09ff00;
    margin-bottom: 10px;
  }
}

@keyframes ball2 {
  0% {
    box-shadow: 30px 0 0 #ffffff;
  }
  50% {
    box-shadow: 0 0 0 #ffffff;
    margin-top: -20px;
    -webkit-transform: translate(15px,15px);
    -moz-transform: translate(15px, 15px);
  }
  100% {
    box-shadow: 30px 0 0 #ffffff;
    margin-top: 0;
  }
}
::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

::-webkit-scrollbar-track {
  background-color: #f1f1f1; 
}

::-webkit-scrollbar-thumb {
  background-color: #696969; 
  border-radius: 6px;
}

::-webkit-scrollbar-thumb:hover {
  background-color: #414141; 
}

@media (max-width: 850px) {
  ::-webkit-scrollbar {
    width: 0;
  }
}


@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.loader2 {
  border: 4px solid rgba(0, 0, 0, 0.1);
  border-top: 4px solid white;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  animation: spin 1s linear infinite;
}

.animate-loader2 {
  display: flex;
  align-items: center;
  justify-content: center;
}

@keyframes bounce {
  0%, 20%, 50%, 80%, 100% {
    transform: translateY(0);
  }
  40% {
    transform: translateY(-5px);
  }
  60% {
    transform: translateY(-5px);
  }
}

.bounce {
  animation: bounce 2s infinite;
}

.scrollbar-none::-webkit-scrollbar{
  display: none;
}
.scrollbar-none {
  -ms-overflow-style: none;  /* Internet Explorer 10+ */
  scrollbar-width: none;     /* Firefox */
}

/* Utility class to prevent text selection during resizing */
.no-select {
  user-select: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
}
